export interface ShirtProps {
  onClose: () => void;
}

export function Shirt({ onClose }: ShirtProps) {
  return (
    <div className="card">
      <h2
        style={{
          textAlign: "center",
        }}
      >
        ส่งเสื้อยืดไปแบบลับๆ
      </h2>
      <h2
        style={{
          textAlign: "center",
        }}
      >
        ตัวละ 299
      </h2>
      <p
        style={{
          textAlign: "center",
        }}
      >
        (ไม่รวมค่าจัดส่ง)
      </p>
      <p
        style={{
          textAlign: "center",
        }}
      >
        ช่วง pre-order ราคาพิเศษ ถึง 31 สิงหาคมนี้เท่านั้น
      </p>

      <ol>
        <li>แก้ไขข้อความในหน้าแมว แล้วเซฟรูป</li>
        <li>เปิด chat box ของเพจแมว (มุมขวาล่าง)</li>
        <li>โพสต์รูปที่เซฟไว้ ไปให้แมว</li>
        <li>
          แจ้งชื่อและที่อยู่ผู้รับ (แมวจะไม่เปิดเผยข้อมูล
          ไม่เก็บข้อมูลไว้ที่อื่นใด และไม่นำข้อมูลไปใช้นอกเหนือจากนี้){" "}
        </li>
        <li>
          แจ้งวันที่ และเวลา ที่อยากให้ของส่งถึงผู้รับ (แมวจะพยายามกะเวลา
          แต่อาจจะไม่เป๊ะ)
        </li>
        <li>
          แจ้งไซส์เสื้อ{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://numsiri.com/%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%A5%E0%B8%B0%E0%B9%80%E0%B8%AD%E0%B8%B5%E0%B8%A2%E0%B8%94%E0%B8%AA%E0%B8%B4%E0%B8%99%E0%B8%84%E0%B9%89%E0%B8%B2/%E0%B9%84%E0%B8%8B%E0%B8%AA%E0%B9%8C%E0%B9%80%E0%B8%AA%E0%B8%B7%E0%B9%89%E0%B8%AD%E0%B8%A2%E0%B8%B7%E0%B8%94%E0%B8%9C%E0%B8%B9%E0%B9%89%E0%B8%8A%E0%B8%B2%E0%B8%A2"
          >
            คลิกเพื่อดูตัวอย่าง
          </a>{" "}
        </li>
        <li>
          โอนเงินมาที่บัญชีผู้ดูแลแมว 337-259969-4 (ไทยพาณิชย์ - พิพรรธน์​
          สำเภากิจ) แล้วส่งสลิปมาใน chat box
        </li>
      </ol>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { FaFacebookF, FaFacebookMessenger, FaTshirt } from "react-icons/fa";
import { Action, Fab } from "react-tiny-fab";
import { ClientSideOnly } from "./components/ClientSideOnly";
import { InBox } from "./components/InBox";
import { Maw, MawRef } from "./components/Maw";
import { Shirt } from "./components/Shirt";
import "./App.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC1nEHtV6WA8vCEoz1SYs_LsEZHH9WIN7c",
  authDomain: "mawsay.firebaseapp.com",
  projectId: "mawsay",
  storageBucket: "mawsay.appspot.com",
  messagingSenderId: "989412731800",
  appId: "1:989412731800:web:7f357760ce15b2564019ed",
  measurementId: "G-PPS8KZKMZT",
};

function initFacebookChatBox() {
  const chatbox = document.getElementById("fb-customer-chat");
  chatbox?.setAttribute("page_id", "101705529267058");
  chatbox?.setAttribute("attribution", "biz_inbox");

  const twindow = window as any;
  twindow.fbAsyncInit = function () {
    twindow.FB.init({
      xfbml: true,
      version: "v11.0",
    });
  };
  (function (d, s, id) {
    let js: any,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/th_TH/sdk/xfbml.customerchat.js";
    fjs?.parentNode?.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
}

export default function App() {
  const [feature, setFeature] = useState<"maw" | "shirt" | "inbox">("maw");
  const mawRef = useRef<MawRef>(null);
  const analyticsRef = useRef<Analytics>();

  useEffect(() => {
    initFacebookChatBox();
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    analyticsRef.current = getAnalytics(app);
  }, []);

  return (
    <>
      <div className={`pageContainer ${feature === "maw" ? "fullHeight" : ""}`}>
        {feature === "maw" && (
          <Maw
            ref={mawRef}
            onFocusMessage={() => {
              if (analyticsRef.current) {
                logEvent(analyticsRef.current, "focus_maw_message");
              }
            }}
            onFocusName={() => {
              if (analyticsRef.current) {
                logEvent(analyticsRef.current, "focus_maw_message");
              }
            }}
          />
        )}
        {feature === "shirt" && (
          <Shirt
            onClose={() => {
              setFeature("maw");
            }}
          />
        )}
        {feature === "inbox" && (
          <InBox
            onClose={() => {
              setFeature("maw");
            }}
          />
        )}

        <div className="toolbar">
          <ClientSideOnly>
            <Fab
              icon={<>แชร์</>}
              alwaysShowTitle={true}
              style={{
                position: "relative",
                top: -20,
                left: -20,
              }}
              mainButtonStyles={{
                backgroundColor: "var(--color-secondary)",
              }}
            >
              <Action
                text="เซฟรูปไปแชร์ใน Facebook"
                style={{
                  backgroundColor: "var(--color-secondary)",
                }}
                onClick={async () => {
                  if (mawRef.current) {
                    if (analyticsRef.current) {
                      logEvent(analyticsRef.current, "capture");
                    }

                    mawRef.current.captureMaw();
                  } else {
                    setFeature("maw");
                  }
                }}
              >
                <FaFacebookF size={28} />
              </Action>
              <Action
                text="ส่งเป็นเสื้อยืดให้เค้า"
                style={{
                  backgroundColor: "var(--color-secondary)",
                }}
                onClick={() => {
                  if (analyticsRef.current) {
                    logEvent(analyticsRef.current, "shirt");
                  }
                  setFeature("shirt");
                }}
              >
                <FaTshirt size={28} />
              </Action>
              <Action
                text="ฝากแมว inbox หาเค้า"
                style={{
                  backgroundColor: "var(--color-secondary)",
                }}
                onClick={() => {
                  if (analyticsRef.current) {
                    logEvent(analyticsRef.current, "inbox");
                  }
                  setFeature("inbox");
                }}
              >
                <FaFacebookMessenger size={28} />
              </Action>
            </Fab>
          </ClientSideOnly>
        </div>
        <div id="fb-root"></div>
        <div id="fb-customer-chat" className="fb-customerchat"></div>
      </div>
    </>
  );
}

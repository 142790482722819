import { useCallback, useEffect, useRef, useState } from "react";

export type SelectionStrategy = "ORDERLY" | "RANDOM";

export interface UseAnimatedTextOptions {
  caretSpeed?: number;
  typeSpeed?: number;
  firstTextDelay?: number;
  nextTextDelay?: number;
  textQueue: string[];
  selectionStrategy?: SelectionStrategy;
  loop?: number;
  caretCharacter?: string;
}

export function useAnimatedTyping(options: UseAnimatedTextOptions) {
  const optionsRef = useRef({
    ...{
      caretSpeed: 400,
      typeSpeed: 100,
      firstTextDelay: 200,
      nextTextDelay: 2000,
      selectionStrategy: "ORDERLY" as SelectionStrategy,
      loop: 1,
      caretCharacter: "_",
    },
    ...options,
  });

  const textStateRef = useRef<{
    animating: string;
    raw: string;
  }>();

  const { selectNext } = useTextSelection(
    optionsRef.current.textQueue,
    optionsRef.current.loop,
    optionsRef.current.selectionStrategy
  );

  const intervalHandleRef = useRef<NodeJS.Timer>();
  const forceUpdate = useForceUpdate();

  const pauseAnimation = useCallback(() => {
    if (intervalHandleRef.current) {
      clearInterval(intervalHandleRef.current);
    }
  }, []);

  const startAnimation = useCallback(() => {
    pauseAnimation();
    intervalHandleRef.current = setInterval(() => {
      if (
        textStateRef.current === undefined ||
        textStateRef.current.animating === textStateRef.current.raw
      ) {
        pauseAnimation();
        setTimeout(
          () => {
            const text = selectNext();
            if (text !== undefined) {
              textStateRef.current = {
                animating: "",
                raw: text,
              };
              forceUpdate();
              startAnimation();
            }
          },
          textStateRef.current === undefined
            ? optionsRef.current.firstTextDelay
            : optionsRef.current.nextTextDelay
        );
      } else {
        const nextAnimatingText = textStateRef.current.raw.slice(
          0,
          textStateRef.current.animating.length + 1
        );
        textStateRef.current = {
          ...textStateRef.current,
          animating: nextAnimatingText,
        };
        forceUpdate();
      }
    }, optionsRef.current.typeSpeed);
  }, [forceUpdate, selectNext, pauseAnimation]);

  const displayText = translateRawText(
    textStateRef.current?.animating ?? "",
    optionsRef.current.caretCharacter
  );
  const { caretOn } = useBlinkingCaret(
    displayText,
    optionsRef.current.caretSpeed
  );
  const caret = caretOn ? optionsRef.current.caretCharacter : "";
  const displayTextWithCaret = displayText + caret;

  return {
    animatingCaret: caret,
    animatingText: displayTextWithCaret,
    startAnimation,
    pauseAnimation,
  };
}

function translateRawText(rawText: string, caretCharacter: string) {
  let text = rawText.replace(new RegExp(caretCharacter, "g"), "");

  // apply backspace
  while (text.indexOf("\b") !== -1) {
    // eslint-disable-next-line no-control-regex
    text = text.replace(/.?\x08/, ""); // 0x08 is the ASCII code for \b
  }
  return text;
}

function useTextSelection(
  textQueue: string[],
  loop: number,
  selectionStrategy: "ORDERLY" | "RANDOM"
) {
  const buffer = useRef<string[]>([]);
  const currentLoop = useRef(0);
  const inputTextQueue = useRef(textQueue);
  const selectNext = useCallback(() => {
    if (buffer.current.length === 0) {
      currentLoop.current += 1;
      if (loop < 0 || currentLoop.current <= loop) {
        buffer.current = inputTextQueue.current.slice();
      } else {
        // no more text, end of the loop
        return undefined;
      }
    }
    if (selectionStrategy === "ORDERLY") {
      return buffer.current.shift() ?? "";
    } else if (selectionStrategy === "RANDOM") {
      return (
        buffer.current.splice(
          Math.floor(Math.random() * buffer.current.length),
          1
        )[0] ?? ""
      );
    } else {
      console.error(`Invalid selectionStrategy: ${selectionStrategy}`);
      return undefined;
    }
  }, [loop, selectionStrategy]);

  return { selectNext };
}

/**
 * loop blinking the caret,
 * reset the loop when animatedText changes so the caret is not blinking while typing
 */
function useBlinkingCaret(animatedText: string, caretSpeed: number) {
  const [caretOn, setCaretOn] = useState(false);
  const caretIntervalHandleRef = useRef<NodeJS.Timer>();
  useEffect(() => {
    if (caretIntervalHandleRef.current) {
      clearInterval(caretIntervalHandleRef.current);
      setCaretOn(true);
    }
    caretIntervalHandleRef.current = setInterval(() => {
      setCaretOn((prevValue) => !prevValue);
    }, caretSpeed);
  }, [animatedText, caretSpeed]);

  return { caretOn };
}

function useForceUpdate() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(0);
  return useCallback(() => setValue((value) => value + 1), []); // update state to force render
}

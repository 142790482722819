import html2canvas from "html2canvas";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FontReadyTextArea } from "./FontReadyTextArea";
import { useAnimatedTyping } from "./useAnimatedTyping";

export interface MawRef {
  captureMaw: () => void;
}

export interface MawProps {
  onFocusMessage: () => void;
  onFocusName: () => void;
}

export const Maw = forwardRef<MawRef, MawProps>(function Maw(
  { onFocusMessage, onFocusName },
  ref
) {
  const [captureMode, setCaptureMode] = useState(false);
  const captureRef = useRef<any>(null);
  const textareaMessageRef = useRef<HTMLTextAreaElement>(null);
  const textareaNameRef = useRef<HTMLTextAreaElement>(null);
  const [message, setMessage] = useState<string>();
  const [name, setName] = useState<string>();

  useImperativeHandle(ref, () => ({
    captureMaw() {
      setCaptureMode(true);
    },
  }));

  const animatingMessage = useAnimatedTyping({
    textQueue: [
      "อยากให้กำลังใจใครสักคน________ อย่างลับๆ________________\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b",
      "มีเรื่องลำบากใจ________ ไม่กล้าบอกเค้าตรงๆ________________\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b",
      "อยากบอกรัก________ แต่ไม่กล้าพูดต่อหน้า________________\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b\b",
    ],
    loop: -1,
    selectionStrategy: "ORDERLY",
  });

  const animatingMawName = useAnimatedTyping({
    textQueue: [
      "I didn't say, mawsay",
      "เราไม่ได้กล่าว แมวกล่าว",
      "บอกใบ้ว่าเราเป็นใคร",
    ],
    loop: -1,
    selectionStrategy: "ORDERLY",
    nextTextDelay: 10000,
    typeSpeed: 50,
  });

  useEffect(() => {
    void document.fonts.ready.then(() => {
      animatingMessage.startAnimation();
      animatingMawName.startAnimation();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function capture() {
      // const { exportComponentAsPNG } = await import("react-component-export-image");
      // await exportComponentAsPNG(captureRef, {
      // fileName: `mawsay-${new Date().toISOString()}.png`,
      // html2CanvasOptions: {},
      // });
      const canvas = await html2canvas(captureRef.current);
      const dataUrl = canvas.toDataURL("image/png");
      const blob = await (await fetch(dataUrl)).blob();
      const filesArray = [
        new File([blob], "mawsay.png", {
          type: blob.type,
          lastModified: new Date().getTime(),
        }),
      ];
      const shareData = {
        files: filesArray,
      };
      if (navigator.canShare && navigator.canShare(shareData)) {
        try {
          await navigator.share(shareData);
        } catch (error) {
          console.log(error);
        }
      } else {
        const d = window?.open()?.document;
        if (d) {
          d.write("");
          d.close();
          d.body.appendChild(document.createElement("img")).src = dataUrl;
        }
      }
    }

    if (captureMode) {
      void capture().finally(() => {
        setCaptureMode(false);
      });
    }
  }, [captureMode]);

  return (
    <>
      {captureMode && <div className="overlay"></div>}
      <div
        className={`contentContainer ${
          captureMode ? "contentContainerCapturing" : ""
        }`}
        ref={captureRef}
      >
        <svg width="100%" height="4px">
          <line
            x1="0"
            y1="0"
            x2="100%"
            y2="0"
            stroke="white"
            strokeWidth="4"
            strokeDasharray="10,5"
          />
        </svg>
        <div className="textContainer">
          <FontReadyTextArea
            captureMode={captureMode}
            ref={textareaMessageRef}
            maxLength={500}
            maxRows={10}
            rows={1}
            className="textAreaMessage"
            value={
              message ??
              `${animatingMessage.animatingText}\n\nไม่ลองพูดผ่านแมวดูล่ะ\n(กดตรงนี้เพื่อพิมพ์ข้อความ)`
            }
            onFocus={() => {
              animatingMessage.pauseAnimation();
              if (message === undefined) {
                setMessage("");
              }
              onFocusMessage();
            }}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
        </div>
        <svg width="100%" height="4px">
          <line
            x1="0"
            y1="0"
            x2="100%"
            y2="0"
            stroke="white"
            strokeWidth="4"
            strokeDasharray="10,5"
          />
        </svg>
        <div className="mawContainer">
          <div className="maw">
            {/* <Image src="/mawsay_big.png" width={556} height={500} alt="mawsay" /> */}
            {/* <Image src="/mawsay_big.png" layout="fill" objectFit="contain" alt="mawsay" /> */}
            <img
              src="/mawsay_big.png"
              alt="dfdf"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          </div>
          <div className="mawName">
            <FontReadyTextArea
              captureMode={captureMode}
              ref={textareaNameRef}
              maxLength={100}
              maxRows={4}
              className="textAreaMawName"
              rows={1}
              value={name ?? animatingMawName.animatingText}
              onFocus={(e) => {
                animatingMawName.pauseAnimation();

                if (name === undefined) {
                  setName(animatingMawName.animatingText.replace("_", ""));
                  setTimeout(() => {
                    if (textareaNameRef.current) {
                      textareaNameRef.current.select();
                    }
                  }, 0);
                } else {
                  e.target.select();
                }
                onFocusName();
              }}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
        </div>
        {captureMode && (
          <div className="creditLine">Powered By mawsay.littlepawcraft.com</div>
        )}
      </div>
    </>
  );
});

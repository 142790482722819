export interface InBoxProps {
  onClose: () => void;
}

export function InBox({ onClose }: InBoxProps) {
  return (
    <div className="card">
      <h1>ให้แมว inbox ไปแบบลับๆ</h1>

      <ol>
        <li>แก้ไขข้อความในหน้าแมว แล้วเซฟรูป</li>
        <li>เปิด chat box ของเพจแมว (มุมขวาล่าง)</li>
        <li>โพสต์รูปที่เซฟไว้ ไปให้แมว</li>
        <li>แจ้ง Facebook ID หรือ Home Page URL ของเค้าคนนั้น</li>
        <li>แจ้งวันที่ และเวลา (เช้า/บ่าย) ที่ต้องการส่งแมวไป</li>
      </ol>

      <h2>เงื่อนไข</h2>
      <ol>
        <li>แมวไม่เปิดเผยข้อมูลของใครเว้นแต่เจ้าตัวอนุญาต</li>
        <li>แมวไม่พูดเรื่องที่ผิดกฎหมาย หมิ่นประมาท หลอกลวง ขู่กรรโชก</li>
        <li>แมวไม่พูดเรื่องที่จะทำให้คนฟังทุกข์ใจ</li>
        <li>
          <a
            href="https://www.facebook.com/mawwsay"
            target="_blank"
            rel="noopener noreferrer"
          >
            ฝากไลค์/แชร์ เพจแมวด้วยน้า
          </a>
        </li>
      </ol>
    </div>
  );
}

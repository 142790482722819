import React from "react";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";

export interface FontReadyTextAreaProps extends TextareaAutosizeProps {
  captureMode?: boolean;
}

/**
 * entirely replace textarea with a new instance when the fonts are ready,
 * so the default fonts are replaced with the proper fonts
 */
export const FontReadyTextArea = React.forwardRef<
  HTMLTextAreaElement,
  FontReadyTextAreaProps
>(function FontReadyTextArea({ captureMode, ...props }, ref) {
  // console.log(props.value);
  return captureMode ? (
    <div
      className={props.className}
      style={{ whiteSpace: "pre-line", overflow: "hidden" }}
    >
      {props.value}
    </div>
  ) : (
    <TextareaAutosize {...props} style={{ overflow: "hidden" }} ref={ref} />
  );
});

import React, { useEffect, useState } from "react";

export interface ClientSideOnlyProps {
  children: JSX.Element;
}
export function ClientSideOnly({ children }: ClientSideOnlyProps) {
  const [isRendering, setIsRendering] = useState(false);

  useEffect(() => {
    setIsRendering(true);
  }, []);

  return isRendering ? children : <></>;
}
